import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import CodeOutput from "./CodeOutput";
import confetti from "canvas-confetti";

const ContactInput = () => {
    const [isSend, setIsSend] = useState(false);
    const [name, setName] = useState();
    const [mail, setMail] = useState();
    const [message, setMessage] = useState();

    const handleCheck = () => {
        setIsSend(!isSend);
        setName("");
        setMail("");
        setMessage("");
    };

    const handleFormSubmit = () => {
        confetti({
            particleCount: 200,
            startVelocity: 30,
            spread: 360,
            origin: {
                x: 0.33,
                y: 0.3,
            },
        });
        setIsSend(true);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const Success = () => {
        return (
            <div className='flex flex-col items-center justify-center max-w-[23.25rem] text-center'>
                <h1 className='text-[#fff] text-[1.625rem] font-display font-[400]'>Thank you! 🤘</h1>
                <h3 className='text-[#fff] text-[1em] font-display font-[400]'>Your message has been accepted. You will recieve answer really soon!</h3>
                <Button
                    onClick={() => handleCheck()}
                    className='text-[#fff] text-[1rem] font-display font-[400] bg-[#1C2B3A] mt-[2rem]'>
                    send-new-message
                </Button>
            </div>
        );
    };

    return (
        <>
            <form
                className='flex flex-col justify-center mt-[-250px] desktop:ml-[155px] tablet:ml-[375px]'
                onSubmit={handleSubmit(() => handleFormSubmit())}>
                {isSend && <Success />}
                <div className={`flex flex-col pb-[20px] ${isSend ? "hidden" : ""}`}>
                    <label
                        for='name'
                        className='text-text-color text-[16px] font-display font-[450] pb-[5px]'>
                        _name:
                    </label>
                    <input
                        {...register("name", { required: "Your name is required!" })}
                        onChange={(event) => setName(event.target.value)}
                        id='name'
                        value={name}
                        className={`w-[372px] h-[41px] bg-[#011221] border-1 ${errors.name ? "border-[#F95959]" : "border-[#1E2D3D]"} rounded-[8px] outline-none text-text-color text-[16px] font-display font-[450] pl-3`}
                        aria-invalid={errors.name ? "true" : "false"}
                    />
                    {errors.name && (
                        <p
                            className='text-[#F95959] text-[14px] font-display font-[450]'
                            role='alert'>
                            *{errors.name.message}
                        </p>
                    )}
                </div>
                <div className={`flex flex-col pb-[20px] ${isSend ? "hidden" : ""}`}>
                    <label
                        for='mail'
                        className='text-text-color text-[16px] font-display font-[450] pb-[5px]'>
                        _email:
                    </label>
                    <input
                        {...register("mail", { required: "Email Address is required!" })}
                        onChange={(event) => setMail(event.target.value)}
                        id='mail'
                        value={mail}
                        className={`w-[372px] h-[41px] bg-[#011221] border-1 ${errors.mail ? "border-[#F95959]" : "border-[#1E2D3D]"} rounded-[8px] outline-none text-text-color text-[16px] font-display font-[450] pl-3`}
                        aria-invalid={errors.mail ? "true" : "false"}
                    />
                    {errors.mail && (
                        <p
                            className='text-[#F95959] text-[14px] font-display font-[450]'
                            role='alert'>
                            *{errors.mail.message}
                        </p>
                    )}
                </div>
                <div className={`flex flex-col pb-[20px] ${isSend ? "hidden" : ""}`}>
                    <label
                        for='message'
                        className='text-text-color text-[16px] font-display font-[450] pb-[5px]'>
                        _message:
                    </label>
                    <textarea
                        {...register("message", { required: "Message Content is required!" })}
                        onChange={(event) => setMessage(event.target.value)}
                        id='message'
                        value={message}
                        className={`w-[372px] h-[150px] bg-[#011221] border-1 ${errors.message ? "border-[#F95959]" : "border-[#1E2D3D]"}  rounded-[8px] outline-none text-text-color text-[16px] font-display font-[450] p-3 resize-none overflow-hidden`}
                        aria-invalid={errors.message ? "true" : "false"}
                    />
                    {errors.message && (
                        <p
                            className='text-[#F95959] text-[14px] font-display font-[450]'
                            role='alert'>
                            *{errors.message.message}
                        </p>
                    )}
                </div>
                <Button
                    className={`rounded-[8px] bg-[#1C2B3A] w-[146px] h-[38px] text-[#FFF] text-[14px] font-display ${isSend ? "hidden" : ""}`}
                    type='submit'>
                    submit-message
                </Button>
            </form>
            <div className='w-[1px] min-h-screen bg-[#1E2D3D] ml-[140px] tablet:hidden mobile:hidden'></div>
            <CodeOutput
                name={name}
                mail={mail}
                message={message}
            />
        </>
    );
};

export default ContactInput;
