import React from "react";
import CodeSnippet from "./CodeSnippet";
import AboutMe from "./AboutMe";

const Main = ({ data }) => {
    return (
        <div className='z-50 min-h w-full min-h-full mt-[102px] grid desktop:grid-cols-2 tablet:grid-cols-2 mobile:grid-cols-1 justify-center overflow-y-auto'>
            <div className='desktop:pb-[15rem] tablet:pb-[15rem] min-h-full desktop:overflow-y-auto tablet:overflow-y-auto'>
                <AboutMe />
            </div>
            <div className='pb-[15rem] mobile:pt-[30rem] mobile:pb-[60rem] desktop:overflow-y-auto tablet:overflow-y-auto min-h-full desktop:border-l-[1.5px] tablet:border-l-[1.5px] border-border-color '>
                <CodeSnippet data={data} />
            </div>
        </div>
    );
};

export default Main;
