import React from "react";

const Title = () => {
    return (
        <div className='desktop:ml-[270px] tablet:ml-[80px] mobile:ml-[30px] z-50 break-words'>
            <p className='text-[#E5E9F0] font-display text-[18px] tracking-[2px]'>Hi all. I am</p>
            <h1 className='text-[#E5E9F0] font-display text-[62px] tracking-[2.5px] leading-[70px]'>Dawid Piela</h1>
            <p className='text-[#4D5BCE] mobile:text-[#43D9AD] font-display desktop:text-[32px] tablet:text-[26px] mobile:text-[20px]'>&gt; Front-end developer</p>
        </div>
    );
};

export default Title;
