import React from "react";
import SubHeader from "./components/SubHeader";
import LeftMenu from "./components/LeftMenu";
import MainContent from "./components/MainContent";

const ContactPage = () => {
    return (
        <div className='z-10 min-w-full'>
            <SubHeader />
            <LeftMenu />
            <MainContent />
        </div>
    );
};

export default ContactPage;
