import React from "react";
import GetThumbnail from "../functions/GetRespoThumbnail";
import { Button } from "@nextui-org/react";
import { Card } from "@nextui-org/react";

const Respozitories = ({ data }) => {
    if (!data) return;

    return (
        <div className='w-screen z-50 min-h-full mt-[102px] overflow-x-hidden'>
            <div className='flex flex-wrap gap-[2.5rem] desktop:mx-[8rem] tablet:mx-[6rem] mobile:justify-center gap-y-[5rem] mt-[5rem] pb-[20rem]'>
                {data.map((respo, index) => {
                    if (respo.name === "Dawid") return;
                    if (respo.name === "odysseymc-bot") return;
                    if (respo.name === "questio") return;

                    return (
                        <Card className='w-[25rem] h-[19rem] bg-[#011221] rounded-[1rem] border-1 border-[#1E2D3D]'>
                            <div className='flex items-center gap-[1.5rem]'>
                                <p className='text-[#5565E8] text-[1rem] font-display font-[600] mt-[-3rem]'>Project {index - 1}</p>
                                <p className='text-[#607B96] text-[1rem] font-display font-[600] mt-[-3rem]'>// _{respo.name}</p>
                            </div>
                            <div className='overflow-hidden rounded-t-[1rem]'>
                                <GetThumbnail respoName={respo.name} />
                            </div>
                            <div className='mx-[2rem] py-4'>
                                <p className='text-[#607B96] text-[14px] font-display rounded-[0.5rem]'>Duis aute irure dolor in velit esse cillum dolore.</p>
                            </div>
                            <div className='mx-[2rem] mt-[0.5rem]'>
                                <Button
                                    onClick={() => window.open(respo.html_url, "_blank")}
                                    className='bg-[#1C2B3A] text-[#FFFFFF] font-display rounded-[0.5rem]'>
                                    view-project
                                </Button>
                                {respo.homepage !== null ? (
                                    <Button
                                        onClick={() => window.open(respo?.homepage, "_blank")}
                                        className='bg-[#1C2B3A] text-[#FFFFFF] font-display rounded-[0.5rem] ml-[0.65rem]'>
                                        _preview
                                    </Button>
                                ) : null}
                            </div>
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default Respozitories;
