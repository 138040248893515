import React from "react";
import { CircularProgress } from "@nextui-org/react";
import { useQuery } from "react-query";
import { getUserData } from "../../../../common/components/Fetchers";

import { ReactComponent as Vector } from "../../../../assets/svg/Vector.svg";
import { ReactComponent as VectorDown } from "../../../../assets/svg/VectorDown.svg";
import { ReactComponent as VectorRight } from "../../../../assets/svg/VectorRight.svg";
import { ReactComponent as RedFolder } from "../../../../assets/svg/RedFolder.svg";
import { ReactComponent as GreenFolder } from "../../../../assets/svg/GreenFolder.svg";
import { ReactComponent as BlueFolder } from "../../../../assets/svg/BlueFolder.svg";
import { ReactComponent as EducationIcon } from "../../../../assets/svg/EductionIcon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/svg//mail-icon.svg";

import SubHeader from "../SubHeader";
import Main from "./Main";

const AboutMeLeftMenu = ({ activeSubPage }) => {
    const { data, isLoading } = useQuery("user", getUserData(), {
        staleTime: 60 * 60 * 1000,
    });

    if (isLoading)
        return (
            <div className='flex justify-center'>
                <CircularProgress
                    classNames={{
                        svg: "w-24 h-24 drop-shadow-md",
                        indicator: "stroke-[#5565E8]",
                        track: "stroke-white/10",
                        value: "text-3xl font-semibold text-[#5565E8]",
                    }}
                    strokeWidth={4}
                    showValueLabel={true}
                    className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                />
            </div>
        );

    if (!data)
        return (
            <div className='flex justify-center'>
                <CircularProgress
                    classNames={{
                        svg: "w-24 h-24 drop-shadow-md",
                        indicator: "stroke-[#5565E8]",
                        track: "stroke-white/10",
                        value: "text-3xl font-semibold text-[#5565E8]",
                    }}
                    strokeWidth={4}
                    showValueLabel={true}
                    className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
                />
            </div>
        );

    return (
        <>
            <SubHeader activeSubPage={activeSubPage} />
            <div className='min-w-[15.05rem] mt-[57px] h-full border-r-[1.5px] border-[#1E2D3D] gap-10 mobile:hidden tablet:hidden'>
                <div className='flex items-center h-[45px] border-y-[1.5px] border-[#1E2D3D]'>
                    <div className='flex items-center gap-3 ml-[24px]'>
                        <Vector />
                        <h1 className='font-display text-[#FFF] text-[14px]'>personal-info</h1>
                    </div>
                </div>
                <div className='flex flex-col border-b-[1.5px] border-[#1E2D3D] pb-[40px] mt-[20px] gap-3'>
                    <div className='flex items-center gap-2 ml-[24px]'>
                        <VectorRight />
                        <RedFolder style={{ marginLeft: 4 }} />
                        <h1 className='font-display text-[#FFF] text-[14px]'>bio</h1>
                    </div>
                    <div className='flex items-center gap-2 ml-[24px] '>
                        <VectorRight />
                        <GreenFolder style={{ marginLeft: 4 }} />
                        <h1 className='font-display text-text-color text-[14px]'>interests</h1>
                    </div>
                    <div className='flex items-center gap-2 ml-[24px]'>
                        <VectorDown />
                        <BlueFolder />
                        <h1 className='font-display text-text-color text-[14px]'>education</h1>
                    </div>
                    <div className='flex items-center gap-2 ml-[45px]'>
                        <EducationIcon />
                        <h1 className='font-display text-text-color text-[14px]'>high-school</h1>
                    </div>
                </div>
                <div className='flex items-center h-[45px] border-b-[1.5px] border-[#1E2D3D]'>
                    <div className='flex items-center gap-3 ml-[24px]'>
                        <Vector />
                        <h1 className='font-display text-[#FFF] text-[14px]'>contacts</h1>
                    </div>
                </div>
                <div className='mt-[20px] gap-2 flex flex-col'>
                    <div className='flex items-center gap-2 ml-[24px] '>
                        <MailIcon />
                        <h1 className='font-display text-text-color text-[14px]'>user@gmail.com</h1>
                    </div>
                </div>
            </div>
            <Main data={data.data} />
        </>
    );
};

export default AboutMeLeftMenu;
