import React, { useState, useEffect } from "react";
import { Card, Skeleton, Image } from "@nextui-org/react";
import axios from "axios";

function GetThumbnail(respoName) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [image, setImage] = useState("");

    const apiUrl = `https://api.github.com/repos/frontenddave/${respoName.respoName}/issues`;

    useEffect(() => {
        axios
            .get(apiUrl, { Authorization: `Bearer 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` })
            .then((response) => {
                setImage(response.data[0].body);
            })
            .finally(setIsLoaded(true))
            .catch((error) => {});
    }, [apiUrl]);

    console.log(image);

    return (
        <Skeleton
            isLoaded={isLoaded}
            className='bg-[#1C2B3A]'>
            <img
                className='mt-[-10px]'
                alt='Respo background'
                src='https://res.cloudinary.com/practicaldev/image/fetch/s--enVmT-G4--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/7j8s2h4erdejm82dyyn7.png'></img>
        </Skeleton>
    );
}

export default GetThumbnail;
