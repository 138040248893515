import React from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./index.css";

import Header from "./common/components/header";
import Footer from "./common/components/footer";

import HomePage from "./pages/home/index";
import AboutMePage from "./pages/about/index";
import ProjectsPage from "./pages/projects/index";
import ContactPage from "./pages/contact/index";

function App() {
    const location = useLocation();

    return (
        <div className='bg-bg h-screen flex overflow-hidden'>
            <Header currentPage={location.pathname} />
            <Footer />
            <Routes>
                <Route
                    path='/'
                    element={<HomePage />}
                />
                <Route
                    path='/about'
                    element={<AboutMePage />}
                />
                <Route
                    path='/projects'
                    element={<ProjectsPage />}
                />
                <Route
                    path='/contact'
                    element={<ContactPage />}
                />
            </Routes>
        </div>
    );
}

export default App;
