import React from "react";
import { ReactComponent as Green } from "../../../assets/svg/Green.svg";
import { ReactComponent as Blue } from "../../../assets/svg/Blue.svg";

import FirstCode from "../../../assets/images/code-snippet4.png";
import SecondCode from "../../../assets/images/code-snippet1.png";
import ThirdCode from "../../../assets/images/code-snippet2.png";
import FourthCode from "../../../assets/images/code-snippet3.png";
import FifthCode from "../../../assets/images/code-snippet5.png";

const Code = () => {
    return (
        <div>
            <div className='absolute right-[220px] top-[65px] code-img-container mobile:hidden tablet:hidden z-50'>
                <img
                    alt='code-snippet'
                    src={FirstCode}
                    className='mt-[15px]'></img>
                <img
                    alt='code-snippet'
                    src={SecondCode}
                    className='mt-[15px]'></img>
                <img
                    alt='code-snippet'
                    src={ThirdCode}
                    className='mt-[15px]'></img>
                <img
                    alt='code-snippet'
                    src={FourthCode}
                    className='mt-[15px]'></img>
                <img
                    alt='code-snippet'
                    src={FifthCode}
                    className='mt-[15px]'></img>
            </div>

            <div className='border-none overflow-hidden'>
                <Green className='h-[830px] w-[881px] mobile:right-[-160px] tablet:right-[-120px] right-[120px] mobile:top-[-120px] top-[-100px] fixed z-[-99]' />
                <Blue className='h-[830px] w-[881px] mobile:right-[-390px] tablet:right-[-100px] right-[70px] mobile:top-[-100px] top-[150px] fixed z-[-99]' />
            </div>
        </div>
    );
};

export default Code;
