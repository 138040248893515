import React, { useState } from "react";

import AboutMeLeftMenu from "./about/LeftMenu";
import TerminalMain from "./terminal/TerminalMain";
import GamesMain from "./games/GamesMain";

import { ReactComponent as TerminalIcon } from "../../../assets/svg/terminalIcon.svg";
import { ReactComponent as AboutIcon } from "../../../assets/svg/AboutIcon.svg";
import { ReactComponent as GamesIcon } from "../../../assets/svg/GamesIcon.svg";

const Sidebar = () => {
    const [active, setActive] = useState("about");

    const filter = {
        terminal: <TerminalMain activeSubPage={active} />,
        about: <AboutMeLeftMenu activeSubPage={active} />,
        games: <GamesMain activeSubPage={active} />,
    };

    return (
        <>
            <div className='min-w-[5rem] mt-[57px] h-full border-[1.5px] border-[#1E2D3D] gap-10 z-50 overflow-hidden bg-[#011627] mobile:hidden'>
                <div className='flex flex-col items-center mt-[2rem] gap-10'>
                    <TerminalIcon
                        onClick={() => setActive("terminal")}
                        style={active === "terminal" ? { cursor: "pointer" } : { cursor: "pointer", opacity: 0.4 }}
                    />
                    <AboutIcon
                        onClick={() => setActive("about")}
                        style={active === "about" ? { cursor: "pointer" } : { cursor: "pointer", opacity: 0.4 }}
                    />
                    <GamesIcon
                        onClick={() => setActive("games")}
                        style={active === "games" ? { cursor: "pointer" } : { cursor: "pointer", opacity: 0.4 }}
                    />
                </div>
            </div>

            {filter[active]}
            {active ? null : <AboutMeLeftMenu activeSubPage='about' />}
        </>
    );
};

export default Sidebar;
