import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem } from "@nextui-org/react";
import { Fade as Hamburger } from "hamburger-react";

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const menuItems = ["_hello", "_about-me", "_projects", "_contact-me"];
    const menuItemsRouting = ["", "about", "projects", "contact"];

    return (
        <Navbar
            className='border'
            isMenuOpen={isMenuOpen}
            onMenuOpenChange={setIsMenuOpen}
            style={{ flexGrow: 0, margin: 0, padding: 0, zIndex: 99 }}
            classNames={{
                base: "border-1 border-[#1E2D3D] backdrop-saturate-100 backdrop-blur-none",
                wrapper: "max-w-[5000px] m-0 p-0",
            }}>
            <NavbarContent style={{ flexGrow: 0, gap: 0 }}>
                <NavbarBrand className={`desktop:border-r-[1.5px] tablet:border-r-[1.5px] border-[#1E2D3D] h-[55px] desktop:w-[320px] tablet:w-[220px]`}>
                    <NavLink
                        to='/'
                        className='font-display text-text-color mx-[24px] text-[14px] cursor-pointer'>
                        dawid-piela
                    </NavLink>
                </NavbarBrand>

                <NavbarItem className='mobile:hidden gap-0 desktop:border-r-[1.5px] tablet:border-r-[1.5px] border-[#1E2D3D] h-[55px] flex justify-center'>
                    <NavLink
                        to='/'
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? "3px solid #FEA55F" : "none",
                                color: isActive ? "#fff" : "#8CA5B5",
                            };
                        }}
                        className='mobile:hidden cursor-pointer w-[122px] flex items-center justify-center'>
                        <h1 className='font-display text-[14px]'>_hello</h1>
                    </NavLink>
                </NavbarItem>
                <NavbarItem className='mobile:hidden gap-0 desktop:border-r-[1.5px] tablet:border-r-[1.5px] border-[#1E2D3D] h-[55px] w-[150px] flex justify-center'>
                    <NavLink
                        to='/about'
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? "3px solid #FEA55F" : "none",
                                color: isActive ? "#fff" : "#8CA5B5",
                            };
                        }}
                        className='mobile:hidden cursor-pointer w-[150px] flex items-center justify-center'>
                        <h1 className='font-display text-[14px]'>_about-me</h1>
                    </NavLink>
                </NavbarItem>
                <NavbarItem className='mobile:hidden gap-0 desktop:border-r-[1.5px] tablet:border-r-[1.5px] border-[#1E2D3D] h-[55px] w-[150px] flex justify-center'>
                    <NavLink
                        to='/projects'
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? "3px solid #FEA55F" : "none",
                                color: isActive ? "#fff" : "#8CA5B5",
                            };
                        }}
                        className='mobile:hidden cursor-pointer w-[150px] flex items-center justify-center'>
                        <h1 className='font-display text-[14px]'>_projects</h1>
                    </NavLink>
                </NavbarItem>
            </NavbarContent>

            <NavbarContent style={{ flexGrow: 0, gap: 0 }}>
                <NavbarItem className='mobile:hidden gap-0 desktop:border-l-[1.5px] tablet:border-l-[1.5px] border-[#1E2D3D] h-[55px] w-[150px] flex justify-center'>
                    <NavLink
                        to='/contact'
                        style={({ isActive }) => {
                            return {
                                borderBottom: isActive ? "3px solid #FEA55F" : "none",
                                color: isActive ? "#fff" : "#8CA5B5",
                            };
                        }}
                        className='mobile:hidden cursor-pointer w-[150px] flex items-center justify-center'>
                        <h1 className='font-display text-[14px]'>_contact-me</h1>
                    </NavLink>
                </NavbarItem>
            </NavbarContent>

            <div className='desktop:hidden tablet:hidden mx-[15px]'>
                <Hamburger
                    color='#8CA5B5'
                    duration={0.5}
                    size={24}
                    toggled={isMenuOpen}
                    toggle={setIsMenuOpen}
                />
            </div>

            <NavbarMenu style={{ backgroundColor: "#011627", padding: 0, gap: 0, rowGap: 0, marginTop: -6 }}>
                {menuItems.map((item, index) => (
                    <NavbarMenuItem
                        style={{ border: "1px solid #1E2D3D", height: 50, display: "flex", alignItems: "center" }}
                        key={`${item}-${index}`}>
                        <NavLink
                            to={`/${menuItemsRouting[index]}`}
                            onClick={() => setIsMenuOpen((isMenuOpen) => !isMenuOpen)}
                            className='font-display mx-[44px] text-[#FFF] text-[14px] cursor-pointer'>
                            {item}
                        </NavLink>
                    </NavbarMenuItem>
                ))}
            </NavbarMenu>
        </Navbar>
    );
};

export default Header;
