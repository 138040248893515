import React from "react";

import SubHeader from "../SubHeader";

const TerminalMain = ({ activeSubPage }) => {
    return (
        <>
            <SubHeader activeSubPage={activeSubPage} />

            <div className='min-w-full mt-[101px]'>
                <div className='m-[20px] break-words mr-[100px]'>
                    <h1 className='text-text-color text-[16px] font-display'>Local Terminal [Version 10.0.22621.2428]</h1>
                    <h2 className='text-text-color text-[16px] font-display'>(c) Artificial intelligence. All rights reserved.</h2>
                    <div className='flex flex-row items-center mt-[40px] justify-start'>
                        <h3 className='text-text-color text-[16px] font-display'>C:\Users\Dawid\Projects\AI&#62;</h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TerminalMain;
