import React from "react";
import { Checkbox } from "@nextui-org/react";
import "../../../index.css";

import { ReactComponent as ReactIcon } from "../../../assets/svg/ReactLogo.svg";
import { ReactComponent as NextIcon } from "../../../assets/svg/NextJsLogo.svg";
import { ReactComponent as JavascriptIcon } from "../../../assets/svg/JavascriptLogo.svg";
import { ReactComponent as TypescriptIcon } from "../../../assets/svg/TypescriptLogo.svg";
import { ReactComponent as HtmlIcon } from "../../../assets/svg/HtmlLogo.svg";
import { ReactComponent as CssIcon } from "../../../assets/svg/CssLogo.svg";

const CustomCheckbox = ({ value }) => {
    const filter = {
        react: <ReactIcon />,
        "react-native": <ReactIcon />,
        next: <NextIcon />,
        javascript: <JavascriptIcon />,
        typescript: <TypescriptIcon />,
        html: <HtmlIcon />,
        css: <CssIcon />,
    };

    return (
        <Checkbox
            className='checkbox'
            classNames={{
                wrapper:
                    "mt-[10px] w-[25px] h-[25px] after:rounded-none after:bg-[#607B96] before:rounded-none rounded-[3px] border-1 group-data-[hover=true]:bg-transparent group-data-[hover=true]:before:bg-transparent border-[#607B96] group-data-[focus-visible=true]:ring-offset-0 group-data-[selected=true]:bg-[#607B96] group-data-[selected=true]:before:bg-[#607B96] group-data-[selected=true]:after:bg-[#607B96]",
                label: "",
            }}
            value={value}>
            <div className='flex flex-row items-center gap-3 mt-[10px]'>
                {filter[value.toLowerCase()]}
                <p className='text-white text-[17px] font-display '>{value.replace("-", " ")}</p>
            </div>
        </Checkbox>
    );
};

export default CustomCheckbox;
