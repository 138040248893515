import React from "react";
import "../../../index.css";

const Comment = () => {
    return (
        <div className='desktop:ml-[270px] tablet:ml-[80px] mobile:ml-[30px] mt-[100px] break-words'>
            <div className='break-words z-50'>
                <p className='text-text-color font-display font-medium z-50'>// Hi, welcome to my site!</p>
                <p className='text-text-color font-display mt-[10px] font-medium z-50'>// To learn more, visit my Github page</p>

                <p className='font-display font-medium z-50 mt-[15px]'>
                    <span className='blue'>const</span> <span className='green'>githubLink</span> <span className='white'>=</span> <span className='orange'>“https://github.com/FrontEndDave”</span>
                    <span className='white'>;</span>
                </p>
            </div>
        </div>
    );
};

export default Comment;
