import React from "react";

import { ReactComponent as Close } from "../../../assets/svg/close-icon.svg";

const SubHeader = ({ activeSubPage }) => {
    console.log(activeSubPage);

    const filter = {
        terminal: "artificial-intelligence",
        about: "personal-info",
    };

    const filterStyle = {
        terminal: "ml-[100px]",
        about: "desktop:ml-[335px] tablet:ml-[100px] mobile:ml-[100px]",
    };

    return (
        <div className='z-[1] h-[45px] w-full absolute top-[57px] border-[1.5px] border-[#1E2D3D] flex items-center'>
            <div className={`${filterStyle[activeSubPage]} h-[45px] flex items-center pr-2 border-r-[1.5px] border-[#1E2D3D]`}>
                <h1 className='font-display text-text-color text-[14px] gap-10 pr'>{filter[activeSubPage]}</h1>
                <Close style={{ width: 18, height: 18, marginLeft: 50, cursor: "pointer" }} />
            </div>
        </div>
    );
};

export default SubHeader;
