import React from "react";

import { ReactComponent as Discord } from "../../../assets/svg/Discord.svg";
import { ReactComponent as Github } from "../../../assets/svg/Github.svg";

const Footer = () => {
    return (
        <div className='fixed bottom-0 h-[60px] w-full border-[1.5px] border-[#1E2D3D] flex justify-between z-50 bg-[#011627]'>
            <div className='flex items-center mobile:justify-between mobile:w-full'>
                <div className='flex items-center h-[60px]'>
                    <h1 className='font-display text-[14px] text-text-color mx-[22px]'>find me in:</h1>
                </div>
                <div className='flex flex-row'>
                    <div className='border-l-[1.5px] border-r-[1.5px] border-[#1E2D3D] w-[60px] h-[60px] flex items-center justify-center'>
                        <Discord
                            onClick={() => window.open("https://discord.com/users/522756771001925642", "_blank")}
                            style={{ cursor: "pointer" }}
                            width={28}
                            height={28}
                        />
                    </div>
                    <div
                        className='desktop:border-r-[1.5px] tablet:border-r-[1.5px] desktop:hidden tablet:hidden border-[#1E2D3D] w-[60px] h-[60px] flex items-center justify-center'
                        onClick={() => window.open("https://github.com/FrontEndDave", "_blank")}>
                        <Github style={{ marginLeft: 5 }} />
                    </div>
                </div>
            </div>
            <div
                onClick={() => window.open("https://github.com/FrontEndDave", "_blank")}
                className='mobile:hidden border-l-[1.5px] border-[#1E2D3D] w-[60] flex items-center justify-center h-[60px] mx-[22px] cursor-pointer '>
                <h1 className='font-display text-[14px] text-text-color pl-[25px]'>@FrontEndDave</h1>
                <Github style={{ marginLeft: 5 }} />
            </div>
        </div>
    );
};

export default Footer;
