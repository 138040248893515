import React from "react";

const CodeOutput = ({ name, mail, message }) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const currentDate = new Date();
    const dayName = daysOfWeek[currentDate.getDay()];
    const dayOfMonth = currentDate.getDate();
    const monthName = months[currentDate.getMonth()];

    return (
        <div className='h-[500px] w-[660px] mt-[-300px] ml-[20px] flex items-center mobile:hidden tablet:hidden'>
            <div className='flex flex-col w-[20px] items-end gap-2'>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>1</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>2</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>3</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>4</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>5</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>6</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>7</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>8</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>9</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>10</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>11</h1>
                <h1 className='text-text-color text-[18px] font-display font-[450]'>12</h1>
            </div>
            <div className='flex flex-col gap-2 pl-10'>
                <div>
                    <span className='pink'>const</span>
                    <span className='dark-blue'> button</span>
                    <span className='pink'> = </span>
                    <span className='dark-blue'>document</span>
                    <span className='gray'>.</span>
                    <span className='dark-blue'>querySelector</span>
                    <span className='gray'>(</span>
                    <span className='yellow'>'#sendBtn'</span>
                    <span className='gray'>);</span>
                </div>
                <div>
                    <span className='pink opacity-0'>{"0"}</span>
                </div>
                <div>
                    <span className='pink'>const</span>
                    <span className='dark-blue'> message</span>
                    <span className='pink'> = </span>
                    <span className='gray'>{"{"}</span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='dark-blue'>name</span>
                    <span className='gray'>: </span>
                    <span className='yellow'>"{name}"</span>
                    <span className='gray'>,</span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='dark-blue'>email</span>
                    <span className='gray'>: </span>
                    <span className='yellow'>"{mail}"</span>
                    <span className='gray'>,</span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='dark-blue'>message</span>
                    <span className='gray'>: </span>
                    <span className='yellow'>"{message}"</span>
                    <span className='gray'>,</span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='gray'>date</span>
                    <span className='gray'>: </span>
                    <span className='yellow'>
                        &#34;{dayName} {dayOfMonth} {monthName}&#34;
                    </span>
                    <span className='gray'></span>
                </div>
                <span className='gray'>{"};"}</span>
                <div>
                    <span className='pink opacity-0'>{"0"}</span>
                </div>
                <div>
                    <span className='dark-blue'>button</span>
                    <span className='gray'>.</span>
                    <span className='dark-blue'>addEventListener</span>
                    <span className='gray'>(</span>
                    <span className='yellow'>'click'</span>
                    <span className='gray'>, </span>
                    <span className='gray'>() </span>
                    <span className='pink'>{"=>"} </span>
                    <span className='gray'>{"{"}</span>
                </div>
                <div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='dark-blue'>from</span>
                    <span className='gray'>.</span>
                    <span className='dark-blue'>send</span>
                    <span className='gray'>(</span>
                    <span className='dark-blue'>message</span>
                    <span className='gray'>);</span>
                </div>
                <span className='gray'>{"});"}</span>
            </div>
        </div>
    );
};

export default CodeOutput;
