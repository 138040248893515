import axios from "axios";

export const getUserData = () => () => axios.get("https://api.github.com/users/frontenddave", {});

export const getRespositoriesData = () => () =>
    axios.get(`https://api.github.com/user/repos`, {
        headers: {
            Authorization: `Bearer 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`,
        },
        params: {
            visibility: "all", // 'all' oznacza pobranie zarówno publicznych, jak i prywatnych repozytoriów
        },
    });
