import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";
import LeftMenu from "./components/LeftMenu";
import Respozitories from "./components/AllRespozitories";

const ProjectsPage = () => {
    const client = new QueryClient();

    return (
        <QueryClientProvider client={client}>
            <div className='z-10 overflow-hidden flex desktop:flex-row tablet:flex-row mobile:flex-col'>
                <LeftMenu />
            </div>
        </QueryClientProvider>
    );
};

export default ProjectsPage;
