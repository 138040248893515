import React from "react";
import { QueryClientProvider, QueryClient } from "react-query";

import Sidebar from "./components/Sidebar";

const AboutMePage = () => {
    const client = new QueryClient();

    return (
        <QueryClientProvider client={client}>
            <div className='z-10 overflow-hidden flex flex-row min-w-full min-h-screen'>
                <Sidebar />
            </div>
        </QueryClientProvider>
    );
};

export default AboutMePage;
