import React from "react";
import ContactInput from "./ContactInput";

const MainContent = () => {
    return (
        <div className='desktop:ml-[270px] 40 mt-[101px] h-screen w-full flex flex-row items-center mobile:justify-center'>
            <ContactInput />
        </div>
    );
};

export default MainContent;
