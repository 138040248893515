import React from "react";

import SubHeader from "../SubHeader";
import { Button } from "@nextui-org/react";

const GamesMain = ({ activeSubPage }) => {
    return (
        <>
            <SubHeader activeSubPage={activeSubPage} />

            <div className='min-w-full flex justify-center mt-[150px]'>
                <div className='m-[20px] break-words mr-[100px] text-center'>
                    <h1 className='text-text-color text-[16px] font-display'>Sorry, Section Currently Not Available</h1>
                    <h2 className='text-text-color text-[16px] font-display mt-[10px]'>Try again later!</h2>

                    <Button
                        onClick={() => (window.location.href = "https://youtu.be/dQw4w9WgXcQ")}
                        className='bg-[#1C2B3A] text-text-color text-[14px] font-display mt-[20px]'>
                        Back Home!
                    </Button>
                </div>
            </div>
        </>
    );
};

export default GamesMain;
