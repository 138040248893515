import React from "react";
import { User } from "@nextui-org/react";

import { ReactComponent as DetailsIcon } from "../../../../assets/svg/detailsIcon.svg";
import { ReactComponent as StarIcon } from "../../../../assets/svg/StarIcon.svg";

const CardHeader = ({ data, number }) => {
    return (
        <div className='flex flex-row items-start justify-between'>
            <User
                name={<h1 className='text-[#5565E8] text-[1.2rem] font-display font-[500]'>@{data.name}</h1>}
                description={<p className='text-text-color text-[1rem] font-display'>Created {number} months ago</p>}
                avatarProps={{
                    src: data.avatar_url,
                }}
            />
            <div className='flex flex-row items-center gap-3'>
                <h2 className='text-text-color text-[1.1rem] font-display flex items-center gap-2 mobile:ml-[20px]'>
                    <DetailsIcon style={{ marginTop: -2 }} /> details
                </h2>
                <h2 className='text-text-color text-[1.1rem] font-display flex items-center mobile:hidden tablet:hidden'>
                    <StarIcon style={{ marginTop: -3 }} /> {Math.floor(Math.random() * 100)}
                </h2>
            </div>
        </div>
    );
};

export default CardHeader;
