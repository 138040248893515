import React from "react";

import { ReactComponent as Vector } from "../../../assets/svg/Vector.svg";
import { ReactComponent as Close } from "../../../assets/svg/close-icon.svg";

const SubHeader = () => {
    return (
        <div className='h-[45px] w-full absolute top-[57px] z-[-1] border-[1.5px] border-[#1E2D3D] flex items-center'>
            <div className='flex items-center gap-3 ml-[24px] h-[45px] desktop:pr-[206.3px] tablet:pr-[157px]  mobile:pr-[80px] border-r-[1.5px] border-[#1E2D3D]'>
                <Vector className='mobile:rotate-[-90deg]' />
                <h1 className='font-display text-[#FFF] text-[14px]'>contacts</h1>
            </div>

            <div className='ml-[10px] h-[45px] flex items-center pr-3 border-r-[1.5px] border-[#1E2D3D]'>
                <h1 className='font-display text-text-color text-[14px] gap-10'>contacts</h1>
                <Close style={{ width: 18, height: 18, marginLeft: 50, cursor: "pointer" }} />
            </div>
        </div>
    );
};

export default SubHeader;
