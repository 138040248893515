import React, { useState } from "react";
import { useQuery } from "react-query";
import { getRespositoriesData } from "../../../common/components/Fetchers";
import { CheckboxGroup } from "@nextui-org/react";

import { ReactComponent as Vector } from "../../../assets/svg/Vector.svg";

import CustomCheckbox from "./CustomCheckbox";
import SubHeader from "./SubHeader";
import Respozitories from "./AllRespozitories";

const LeftMenu = () => {
    const { data } = useQuery("github", getRespositoriesData());
    const [selected, setSelected] = useState([]);

    return (
        <>
            <SubHeader selected={selected} />
            <div className='desktop:min-w-[321px] tablet:w-[221px] mt-[57px] h-full mobile:hidden border-[1.5px] border-[#1E2D3D]'>
                <div className='flex items-center h-[45px] desktop:w-[319px] tablet:w-[221px] '>
                    <div className='flex items-center gap-3 ml-[24px]'>
                        <Vector />
                        <h1 className='font-display text-[#FFF] text-[14px]'>projects</h1>
                    </div>
                </div>
                <CheckboxGroup
                    className='ml-[1.5rem] mt-[1rem]'
                    color='warning'
                    value={selected}
                    onValueChange={setSelected}>
                    <CustomCheckbox value='React' />
                    <CustomCheckbox value='React-Native' />
                    <CustomCheckbox value='Next' />
                    <CustomCheckbox value='JavaScript' />
                    <CustomCheckbox value='TypeScript' />
                    <CustomCheckbox value='HTML' />
                    <CustomCheckbox value='CSS' />
                </CheckboxGroup>
            </div>

            <Respozitories data={data?.data} />
        </>
    );
};

export default LeftMenu;
