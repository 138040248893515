import React from "react";

import Title from "./components/MainTitle";
import Comment from "./components/Comment";
import Code from "./components/Code";

const HomePage = () => {
    return (
        <div className='flex flex-col justify-center z-10'>
            <Title />
            <Comment />
            <Code />
        </div>
    );
};

export default HomePage;
