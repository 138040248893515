import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";

const code = `/** 
 * O Mnie
 * Cześć, Jestem Dawid, pasjonnuję się
 * tworzeniem aplikacji webowych i mobilnych.
 * Fascynuje mnie możliwość zamieniania
 * koncepcji w działające projekty poprzez kodowanie.
 * W połączeniu z dbałością o design i użyteczność,
 * tworzę kompleksowe doświadczenia.
 * Chcę aktywnie kształtować technologiczną przyszłość,
 * przynosząc innowacyjne rozwiązania.
 * 
 * Jestem otwarty na naukę,
 * współpracę i wszelkie wyzwania,
 * które przyniesie przyszłość!
*/`;

const AboutMe = () => {
    return (
        <div className='desktop:mx-[2rem] tablet:mx-[1rem] mobile:mx-[1rem]'>
            <h1 className='text-text-color text-[1.125rem] font-display mt-[2rem] desktop:hidden tablet:hidden'>// personal-info / bio</h1>
            <SyntaxHighlighter
                className='syntax-highlighter'
                customStyle={{ background: "transparent", color: "#8CA5B5", fontFamily: "Fira Code, Sans-serif", fontSize: 19, marginTop: 30, transform: "none" }}
                wrapLongLines={true}
                lineProps={{ style: { paddingBottom: 1.5 } }}
                lineNumberStyle={{ color: "#8CA5B5", marginLeft: -8, marginRight: 30, paddingRight: 0, paddingLeft: 0, paddingTop: 1.5, fontSize: 19, fontFamily: "Fira Code, sans-serif" }}
                showLineNumbers={true}>
                {code}
            </SyntaxHighlighter>
        </div>
    );
};

export default AboutMe;
