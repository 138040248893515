import React from "react";

import { ReactComponent as Mail } from "../../../assets/svg/mail-icon.svg";
import { ReactComponent as Phone } from "../../../assets/svg/phone-icon.svg";
import { ReactComponent as Vector } from "../../../assets/svg/Vector.svg";
import { ReactComponent as Link } from "../../../assets/svg/Link.svg";

const LeftMenu = () => {
    return (
        <>
            <div className='desktop:w-[320.5px] tablet:w-[271px] mt-[101px] h-full border-r-[1.5px] border-[#1E2D3D] absolute mobile:hidden '>
                <div className='flex items-center gap-2 ml-[24px] mt-[20px]'>
                    <Mail />
                    <p className='font-display text-text-color text-[16px]'>user@gmail.com</p>
                </div>
                <div className='flex items-center gap-2 ml-[24px] mt-[10px]'>
                    <Phone />
                    <p className='font-display text-text-color text-[16px]'>+3598246359</p>
                </div>

                <div className='flex items-center h-[45px] desktop:w-[320.5px] tablet:w-[271px] border-[1.5px] border-[#1E2D3D] mt-[20px] '>
                    <div className='flex items-center gap-3 ml-[24px]'>
                        <Vector />
                        <p className='font-display text-[#FFF] text-[14px]'>find-me-also-in</p>
                    </div>
                </div>

                <div className='flex items-center gap-2 ml-[24px] mt-[20px]'>
                    <Link />
                    <p className='font-display text-text-color text-[16px]'>Github profile</p>
                </div>
                <div className='flex items-center gap-2 ml-[24px] mt-[10px]'>
                    <Link />
                    <p className='font-display text-text-color text-[16px]'>Instagram accaunt</p>
                </div>
                <div className='flex items-center gap-2 ml-[24px] mt-[10px]'>
                    <Link />
                    <p className='font-display text-text-color text-[16px]'>LinkedIn profile</p>
                </div>
            </div>
        </>
    );
};

export default LeftMenu;
