import React from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import CardHeader from "./UserInfo";

const code = `const numbers = [5, 2, 9, 1, 5, 6];
numbers.sort((a, b) => a - b);

console.log("Posortowane liczby rosnąco:", numbers);

const numbers = [5, 2, 9, 1, 5, 6];
numbers.sort((a, b) => b - a);

console.log("Posortowane liczby malejąco:", numbers);`;

const secondCode = `const generateUniqueRandomNumbers = (min, max, count) => {
    if (max - min + 1 < count) {
      console.log("Nie można wygenerować tylu unikalnych liczb z zakresu.");
    }
  
    const uniqueNumbers = new Set();
  
    while (uniqueNumbers.size < count) {
      const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
      uniqueNumbers.add(randomNum);
    }
  
    return Array.from(uniqueNumbers);
  }
  
  const uniqueRandomNumbers = generateUniqueRandomNumbers(1, 10, 5);
  console.log("Unikalne losowe liczby:", uniqueRandomNumbers);`;

const CodeSnippet = ({ data }) => {
    return (
        <div className='desktop:mx-[2rem] tablet:mx-[1rem] mobile:mx-[1rem] mobile:pt-[15rem]'>
            <h1 className='text-text-color text-[1.125rem] font-display mt-[2rem]'>// Code snippet showcase:</h1>
            <div className='mt-[3rem]'>
                <CardHeader
                    data={data}
                    number={Math.floor(Math.random() * 10) + 1}
                />
                <div className='bg-[#011221] w-full py-1 mt-[1rem] rounded-[1rem] border-[1.5px] border-border-color'>
                    <SyntaxHighlighter
                        customStyle={{ background: "transparent" }}
                        language='react'
                        style={atomOneDark}>
                        {code}
                    </SyntaxHighlighter>
                </div>
            </div>
            <div className='mt-[3rem]'>
                <CardHeader
                    data={data}
                    number={Math.floor(Math.random() * 10) + 1}
                />
                <div className='bg-[#011221] w-full py-1 mt-[1rem] rounded-[1rem] border-[1.5px] border-border-color'>
                    <SyntaxHighlighter
                        customStyle={{ background: "transparent" }}
                        language='react'
                        style={atomOneDark}>
                        {secondCode}
                    </SyntaxHighlighter>
                </div>
            </div>
        </div>
    );
};

export default CodeSnippet;
