import React from "react";
import { CheckboxGroup } from "@nextui-org/react";

import { ReactComponent as Close } from "../../../assets/svg/close-icon.svg";
import CustomCheckbox from "./CustomCheckbox";

const SubHeader = ({ selected }) => {
    const [selectedFilter, setSelectedFilter] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <>
            <div className='h-[45px] w-full absolute top-[57px] z-[-1] border-[1.5px] border-[#1E2D3D] flex items-center'>
                <div className='desktop:ml-[335px] tablet:ml-[230px] h-[45px] flex items-center pr-3 border-r-[1.5px] border-[#1E2D3D] mobile:hidden'>
                    <h1 className='font-display text-text-color text-[14px] gap-10 pr'>{selected.length > 0 ? selected.join("; ").replace("-", " ") : "undefininded"} </h1>
                    <Close style={{ width: 18, height: 18, marginLeft: 50, cursor: "pointer" }} />
                </div>
                <div className='desktop:hidden tablet:hidden mobile:pl-[3.5rem] h-[45px] flex items-center pr-3 bg-[#1E2D3D] w-full'>
                    <h1
                        onClick={() => setIsOpen(!isOpen)}
                        className='font-display text-text-color text-[14px] cursor-pointer'>
                        Select language
                    </h1>
                </div>
            </div>
            {isOpen && (
                <div className='desktop:hidden tablet:hidden mt-[100px]'>
                    <CheckboxGroup
                        className='ml-[3.5rem] mt-[1.5rem]'
                        color='warning'
                        value={selectedFilter}
                        onValueChange={setSelectedFilter}>
                        <CustomCheckbox value='React' />
                        <CustomCheckbox value='React-Native' />
                        <CustomCheckbox value='Next' />
                        <CustomCheckbox value='JavaScript' />
                        <CustomCheckbox value='TypeScript' />
                        <CustomCheckbox value='HTML' />
                        <CustomCheckbox value='CSS' />
                    </CheckboxGroup>
                </div>
            )}
        </>
    );
};

export default SubHeader;
